.recipe-list-item {
  width: 300px;
  margin: 0 2rem 2rem 0;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  &.saved {
    box-shadow: 0 0 1rem #333;
  }

  .image {
    width: 100%;
  }

  .details {
    padding: 0.2rem 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .title {
    font-weight: bold;
    text-transform: uppercase;
  }

  .action-container {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .action {
      button {
        margin-left: 0.5rem;
      }
    }
  }
}
