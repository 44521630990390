.recipe-list-container {
  margin-top: 2rem;

  .filters-container {
    display: flex;
    flex-wrap: wrap;
  }

  .clear-filters-button {
    margin-top: 0.5rem;
  }

  .grid {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .empty-message {
      font-weight: bold;
    }
  }
}
