.app {
  position: relative;
  padding-top: 5rem;
  color: #333;
}

.app-header {
  $menu-height: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid #333;
  background: #fff;
  width: calc(100% - 4rem);
  height: $menu-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  .app-title {
    font-size: 2rem;
  }

  .saved-recipes-toggle {
    width: 7rem;
  }

  .saved-recipes {
    $width: 240px;
    $x-closed: calc($width + 5rem);
    $x-open: 0;
    position: fixed;
    top: $menu-height;
    right: 0;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    height: calc(100vh - $menu-height - 2rem);
    width: $width;
    transform: translateX($x-closed);
    transition: transform 0.5s ease;
    background: #fff;
    padding: 1rem 1.5rem;
    overflow-y: scroll;

    &.open {
      transform: translateX($x-open);
    }

    .saved-recipes-title {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-size: 1.2rem;
      width: 100%;
      text-align: center;
    }

    .recipe-list-item {
      width: $width;

      &.saved {
        box-shadow: none;
      }
    }

    .clear-saved-recipes {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}

.app-content {
  padding: 0 2rem;
}

a,
button {
  cursor: pointer;
  background: transparent;
  padding: 0.2rem 0.5rem;
  border: 1px solid #333;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-size: 1rem;
  line-height: 1.2rem;
  text-decoration: none;
  color: #333;
  background: transparent;

  &.primary {
    background: #555;
    color: #fff;
  }
}
