.filter {
  margin-right: 5rem;

  .filter-title {
    font-weight: bold;
    text-transform: uppercase;
  }

  .filter-option {
    display: block;
    margin: 0.5rem 0;
  }
}